<script lang="ts" setup>
import { UserRoles } from "@/shared/enums";
import { ChatDotRound } from "@element-plus/icons-vue";
const { t } = useI18n();
const { width } = useWindowSize();
const route = useRoute();
const localePath = useLocalePath();

const { data: user } = useAuth();
const adminButton = computed(() => {
  return (
    user.value?.role === UserRoles.Admin ||
    user.value?.role === UserRoles.Moderator
  );
});
const showChatButton = computed(() => {
  const restrictedPath = [
    "support-chat",
    "exchange/requests/",
    "trade/orders/",
  ];
  const anyOfRestrictedPath = restrictedPath.some((path) =>
    route.path.includes(path)
  );
  return !anyOfRestrictedPath && !adminButton.value;
});
const counter = useInterval(1000);
const mounted = ref(false);
onMounted(() => {
  mounted.value = true;
});
const showMobileMenu = computed(() => mounted.value && width.value < 588);
</script>

<template>
  <el-container>
    <el-header class="main-header" height="auto">
      <HeaderMenu />
    </el-header>
    <el-main>
      <slot />
    </el-main>
    <el-footer height="auto" class="main-footer">
      <ClientOnly>
        <template #fallback>
          <el-skeleton animated>
            <template #template>
              <div style="width: 50%; margin: 0 auto 20px">
                <el-skeleton-item variant="p" style="width: 50%" />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-items: space-between;
                  "
                >
                  <el-skeleton-item variant="text" />
                </div>
              </div>
            </template>
          </el-skeleton>
        </template>
        <FooterMenu />
      </ClientOnly>
      <p class="pb-5 opacity-80">
        Solid Swap © 2024 / {{ t("l-default-rightsReserved") }}
      </p>
    </el-footer>
    <Transition name="fade">
      <FooterMobileMenu v-if="showMobileMenu" />
    </Transition>
    <ClientOnly>
      <div class="support-button-cont" v-if="showChatButton">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="t('p-support-chat-welcome')"
          placement="top-start"
          :auto-close="3000"
        >
          <nuxt-link :to="localePath('/support-chat')">
            <el-button
              size="large"
              type="primary"
              :class="counter % 100 > 98 ? 'jump' : ''"
              circle
            >
              <el-icon><ChatDotRound /></el-icon>
            </el-button>
          </nuxt-link>
        </el-tooltip>
      </div>
    </ClientOnly>
  </el-container>
</template>

<style lang="scss" scoped>
.jump {
  animation: jump-animation 1s linear infinite;
}

@keyframes jump-animation {
  0%,
  29.9%,
  30%,
  59.9%,
  60% {
    transform: translateY(0);
  }
  1%,
  31% {
    transform: translateY(-3px);
  }
}
.support-button-cont {
  z-index: 999;
  position: fixed;
  bottom: 70px;
  right: 10px;
  & > * {
    box-shadow: 1px 2px 5px #0009;
  }
  .el-icon {
    font-size: 30px;
  }
}
.main-footer {
  margin-top: 2rem;
  text-align: center;
}
.footer-menu {
  padding: 0;
  &::after {
    content: "";
    clear: both;
    float: none;
    display: block;
  }
  li {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    a {
      color: var(--el-color-primary);
    }
    @media (max-width: 988px) {
      display: block;
      float: left;
      width: 50%;
      box-sizing: border-box;
    }

    &:nth-child(2n - 1) {
      &::before {
        display: none;
      }
    }
  }
  li + li {
    margin-left: 40px;
    @media (max-width: 988px) {
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      height: 100%;
      width: 1px;
      background: var(--el-border-color-darker);
      @media (max-width: 988px) {
        left: 0;
      }
    }
  }
}
.main-header {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--el-bg-color);
  box-shadow: inset 0 -1px 0 var(--el-border-color), 0 5px 10px #0002;
}
</style>
