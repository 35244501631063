<script setup lang="ts">
import { fetchImage } from "@/api";
const { t } = useI18n();
const { data: user, signOut } = useAuth();
const localePath = useLocalePath();
import {
  ArrowDown,
  UserFilled,
  WalletFilled,
  ShoppingBag,
  Tickets,
  Fold,
  Setting,
  User,
  Bell,
} from "@element-plus/icons-vue";
import { UserRoles } from "@/shared/enums";
const route = useRoute();
const router = useRouter();
const color = useColorMode();
const activeIndex = computed<string>(() => {
  const path = route.path;
  if (path.includes("/trade/")) return "/trade";
  return path;
});
const colorSwitch = ref<boolean>(false);
onMounted(() => {
  colorSwitch.value = color.value === "dark";
  watch(colorSwitch, () => {
    color.preference = color.value === "dark" ? "light" : "dark";
  });
});

const UseNotifications = useNotificationsStore();
const { lastUnread } = storeToRefs(UseNotifications);
const { notificationsDisconnect } = UseNotifications;

const logOut = async () => {
  notificationsDisconnect();
  await signOut({ redirect: false });
  router.push(localePath("/login"));
};
const drawer = ref<boolean>(false);
const isAdmin = computed<boolean>(() => user.value?.role === UserRoles.Admin);
const isModer = computed<boolean>(
  () => user.value?.role === UserRoles.Moderator
);
</script>

<template>
  <div class="main-menu">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      router
      :ellipsis="false"
      menu-trigger="click"
      close-on-click-outside
      unique-opened
    >
      <li class="grid-self-center menu-item h-full">
        <NuxtLink
          :to="localePath('/')"
          class="display-logo m-b-4 decoration-none"
          flex="~"
          gap-4
          items-center
        >
          <div class="logo-img"></div>
          <div class="title uppercase title-text">Solid Swap</div>
        </NuxtLink>
      </li>
      <li class="flex-grow" />
      <li class="grid-self-center mr-3 hidden-md-and-down">
        <ClientOnly>
          <template #fallback>
            <el-skeleton style="width: 30px" animated>
              <template #template>
                <el-skeleton-item variant="text" animated />
              </template>
            </el-skeleton>
          </template>
          <LocalePicker />
        </ClientOnly>
      </li>
      <el-switch
        class="grid-self-center mr-3 hidden-md-and-down"
        v-model="colorSwitch"
        inline-prompt
        :active-text="t('c-header-menu-dark')"
        :inactive-text="t('c-header-menu-light')"
        size="default"
      ></el-switch>
      <el-menu-item
        :index="localePath('/trade')"
        class="grid-self-center menu-item hidden-md-and-down"
      >
        <NuxtLink :to="localePath('/trade')">{{
          t("c-header-menu-trade")
        }}</NuxtLink>
      </el-menu-item>
      <el-menu-item
        :index="localePath('/exchange')"
        class="grid-self-center menu-item hidden-md-and-down"
      >
        <NuxtLink :to="localePath('/exchange')">{{
          t("p-exchange-exchange")
        }}</NuxtLink>
      </el-menu-item>
      <client-only>
        <template #fallback>
          <div class="el-sub-menu hidden-md-and-down">
            <div
              class="el-sub-menu__title el-tooltip__trigger el-tooltip__trigger"
            >
              {{ t("c-header-menu-blog") }}
              <el-icon class="el-icon el-sub-menu__icon-arrow">
                <ArrowDown />
              </el-icon>
            </div>
          </div>
          <div class="el-sub-menu hidden-md-and-down">
            <div
              class="el-sub-menu__title el-tooltip__trigger el-tooltip__trigger"
            >
              {{ t("c-header-menu-support") }}
              <el-icon class="el-icon el-sub-menu__icon-arrow">
                <ArrowDown />
              </el-icon>
            </div>
          </div>
        </template>
        <el-sub-menu :index="localePath('/news')" class="hidden-md-and-down">
          <template #title>{{ t("c-header-menu-blog") }}</template>
          <el-menu-item :index="localePath('/blog')">{{
            t("c-header-menu-blog")
          }}</el-menu-item>
          <el-menu-item :index="localePath('/news')">{{
            t("c-header-menu-news")
          }}</el-menu-item>
        </el-sub-menu>
        <el-sub-menu :index="localePath('/faq')" class="hidden-md-and-down">
          <template #title>{{ t("c-header-menu-support") }}</template>
          <el-menu-item :index="localePath('/faq')">{{
            t("c-header-menu-faq")
          }}</el-menu-item>
          <el-menu-item :index="localePath('/beginners-guide')">{{
            t("c-header-menu-beginnersGuide")
          }}</el-menu-item>
          <el-menu-item :index="localePath('/help')">{{
            t("c-header-menu-help")
          }}</el-menu-item>
          <el-menu-item :index="localePath('/support-chat')">
            {{ t("c-header-menu-chat") }}
          </el-menu-item>
        </el-sub-menu>
      </client-only>
      <el-menu-item
        :index="localePath('/about')"
        class="grid-self-center menu-item hidden-md-and-down"
      >
        <NuxtLink :to="localePath('/about')">{{
          t("c-header-menu-about")
        }}</NuxtLink>
      </el-menu-item>
      <li class="hidden-lg-and-up grid-self-center inline-flex">
        <el-button @click="drawer = true">
          <el-icon class="mr-2"><Fold /></el-icon>
          <el-badge
            type="primary"
            class="line-height-relaxed"
            :value="lastUnread.length"
            :hidden="!lastUnread.length"
          >
            {{ t("c-header-menu-menu") }}
          </el-badge>
        </el-button>
      </li>
      <ClientOnly>
        <template #fallback>
          <li
            class="grid-self-center inline-flex ml-4 hidden-md-and-down"
            style="min-width: 85px"
          >
            <el-skeleton
              animated
              style="--el-skeleton-circle-size: 30px"
              class="v-middle"
            >
              <template #template>
                <el-skeleton-item
                  variant="text"
                  style="width: 30%; margin-right: 20px"
                />
                <el-skeleton-item animated variant="circle" />
              </template>
            </el-skeleton>
          </li>
        </template>
        <li v-if="user" class="grid-self-center inline-flex hidden-md-and-down">
          <Notifications />
          <el-dropdown trigger="click">
            <el-button text>
              <el-badge
                :value="`${
                  isAdmin ? t('c-header-menu-admin') : t('c-header-menu-moder')
                }`"
                type="danger"
                :hidden="!isAdmin && !isModer"
              >
                <el-avatar
                  :icon="UserFilled"
                  :src="user.pic === '' ? '' : fetchImage(user.pic)"
                  size="small"
                />
              </el-badge>
            </el-button>
            <template #dropdown>
              <div class="pl-2 pr-2">
                <h2 class="pl-3 pt-3">
                  {{ user.nickname }}
                </h2>
                <el-dropdown-menu class="auth-menu">
                  <el-dropdown-item
                    @click="router.push(localePath(`/profile/${user.userId}`))"
                  >
                    <el-icon><User /></el-icon>
                    {{ t("c-header-menu-profile") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="router.push(localePath('/trade/orders'))"
                  >
                    <el-icon><ShoppingBag /></el-icon>
                    {{ t("c-header-menu-orders") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="router.push(localePath('/trade/profile'))"
                  >
                    <el-icon><Tickets /></el-icon>
                    {{ t("c-header-menu-paymentOptions") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="router.push(localePath('/user/wallet'))"
                  >
                    <el-icon><WalletFilled /> </el-icon>
                    {{ t("c-header-menu-wallet") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="router.push(localePath('/user/settings'))"
                  >
                    <el-icon><Setting /></el-icon>
                    {{ t("c-header-menu-settings") }}
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <el-button @click="logOut()" size="small">
                      {{ t("c-header-menu-logout") }}
                    </el-button></el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </template>
          </el-dropdown>
        </li>
        <li
          v-else
          class="grid-self-center inline-flex ml-4 auth-button hidden-md-and-down"
        >
          <nuxt-link
            :to="localePath('/login')"
            class="mr-3 inline-flex no-underline"
          >
            <el-button size="small" type="primary">{{
              t("c-header-menu-login")
            }}</el-button>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/registration')"
            class="inline-flex no-underline"
          >
            <el-button size="small">{{
              t("c-header-menu-registration")
            }}</el-button>
          </nuxt-link>
        </li>
      </ClientOnly>
    </el-menu>
    <ClientOnly>
      <el-drawer v-model="drawer" size="80%" class="menu-drawer">
        <div class="top-controls">
          <el-switch
            class="grid-self-center ml-5 mr-2 drawer-switch"
            v-model="colorSwitch"
            inline-prompt
            :active-text="t('c-header-menu-dark')"
            :inactive-text="t('c-header-menu-light')"
            size="default"
          ></el-switch>
          <div class="vertical-middle inline-block grid-self-center">
            <LocalePicker />
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          @select="drawer = false"
          unique-opened
          mode="vertical"
          router
          :ellipsis="false"
        >
          <el-menu-item
            :index="localePath('/trade')"
            class="grid-self-center menu-item"
          >
            <NuxtLink :to="localePath('/trade')">{{
              t("c-header-menu-trade")
            }}</NuxtLink>
          </el-menu-item>
          <el-menu-item
            :index="localePath('/exchange')"
            class="grid-self-center menu-item"
          >
            <NuxtLink :to="localePath('/exchange')">{{
              t("p-exchange-exchange")
            }}</NuxtLink>
          </el-menu-item>
          <el-sub-menu :index="localePath('/news')" class="">
            <template #title>{{ t("c-header-menu-blog") }}</template>
            <el-menu-item :index="localePath('/blog')">{{
              t("c-header-menu-blog")
            }}</el-menu-item>
            <el-menu-item :index="localePath('/news')">{{
              t("c-header-menu-news")
            }}</el-menu-item>
          </el-sub-menu>
          <el-sub-menu :index="localePath('/faq')" class="">
            <template #title>{{ t("c-header-menu-support") }}</template>
            <el-menu-item :index="localePath('/faq')">{{
              t("c-header-menu-faq")
            }}</el-menu-item>
            <el-menu-item :index="localePath('/beginners-guide')">{{
              t("c-header-menu-beginnersGuide")
            }}</el-menu-item>
            <el-menu-item :index="localePath('/help')">{{
              t("c-header-menu-help")
            }}</el-menu-item>
            <el-menu-item :index="localePath('/support-chat')">
              {{ t("c-header-menu-chat") }}
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item
            :index="localePath('/about')"
            class="grid-self-center menu-item"
          >
            <NuxtLink :to="localePath('/about')">{{
              t("c-header-menu-about")
            }}</NuxtLink>
          </el-menu-item>
          <el-divider></el-divider>
          <li v-if="user" class="">
            <div>
              <el-avatar
                class="mr-3"
                :icon="UserFilled"
                :src="user.pic === '' ? '' : fetchImage(user.pic)"
                size="small"
              />
              <span class="font-size-6">{{ user.nickname }}</span>
            </div>
            <el-menu
              :default-active="activeIndex"
              @select="drawer = false"
              unique-opened
              mode="vertical"
              router
              :ellipsis="false"
            >
              <el-menu-item :index="localePath('/user/notifications')">
                <el-icon><Bell /></el-icon>
                <el-badge
                  type="primary"
                  class="line-height-relaxed"
                  :value="lastUnread.length"
                  :hidden="!lastUnread.length"
                >
                  {{ t("c-header-menu-notifications") }}
                </el-badge>
              </el-menu-item>
              <el-menu-item
                :index="localePath(`/profile/${user.userId}`)"
                class="grid-self-center menu-item"
              >
                <el-icon><User /></el-icon>
                {{ t("c-header-menu-profile") }}
              </el-menu-item>
              <el-menu-item
                :index="localePath('/trade/orders')"
                class="grid-self-center menu-item"
              >
                <el-icon><ShoppingBag /></el-icon>
                {{ t("c-header-menu-orders") }}
              </el-menu-item>
              <el-menu-item
                :index="localePath('/trade/profile')"
                class="grid-self-center menu-item"
              >
                <el-icon><Tickets /></el-icon>
                {{ t("c-header-menu-paymentOptions") }}
              </el-menu-item>
              <el-menu-item
                :index="localePath('/user/wallet')"
                class="grid-self-center menu-item"
              >
                <el-icon><WalletFilled /> </el-icon>
                {{ t("c-header-menu-wallet") }}
              </el-menu-item>
              <el-menu-item
                :index="localePath('/user/settings')"
                class="grid-self-center menu-item"
              >
                <el-icon><Setting /></el-icon>
                {{ t("c-header-menu-settings") }}
              </el-menu-item>
            </el-menu>
            <el-divider></el-divider>
            <div class="mb-30 w-full justify-center flex">
              <el-button @click="logOut()" size="large">
                {{ t("c-header-menu-logout") }}
              </el-button>
            </div>
          </li>
          <li v-else class="mb-4 w-full justify-center flex">
            <nuxt-link to="/login" class="mr-3 inline-flex no-underline">
              <el-button @click="drawer = false" size="large" type="primary">
                {{ t("c-header-menu-login") }}
              </el-button>
            </nuxt-link>
            <nuxt-link to="/registration" class="inline-flex no-underline">
              <el-button @click="drawer = false" size="large">
                {{ t("c-header-menu-registration") }}
              </el-button>
            </nuxt-link>
          </li>
        </el-menu>
      </el-drawer>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
:deep(.menu-drawer) {
  .top-controls {
    position: absolute;
    top: 15px;
  }
  .menu-item,
  .el-sub-menu,
  .el-menu-item {
    min-height: 3rem;
  }
  .el-drawer__header {
    margin-bottom: 5px;
  }
  .el-menu {
    border: none;
  }
  .el-menu-item,
  .el-sub-menu__title {
    font-size: 1.2rem;
    a {
      color: var(--el-text-color);
    }
  }
}
.auth-menu {
  a {
    color: var(--el-text-color);
  }
}
.user-dropdown {
  width: 200px;
}
@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main-menu {
  position: relative;
  a {
    text-decoration: none;
  }
}

.display-logo {
  height: 100%;
  align-self: baseline;
  &:hover {
    .logo-img::before {
      animation: fadeInFromNone 1s ease-out;
      animation-delay: 0.5s;
    }
  }
  .logo-img {
    line-height: normal;
    vertical-align: middle;
    position: relative;
    height: 52px;
    width: 54px;
    min-width: 54px;
    display: inline-block;
    background: 0 0 url(/img/logo/logo.png) no-repeat;
    background-size: cover;
    transition: width 0.3s linear, opacity 0.3s linear,
      box-shadow 0.1s linear 0.3s;

    &.auth {
      opacity: 0;
      width: 0;
      box-shadow: inset -20px 0 10px 0 var(--el-bg-color-overlay);
      transition: width 0.3s linear, opacity 0.3s linear,
        box-shadow 0.1s linear 0s;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      background: 0 0 url(/img/logo/logo.png) no-repeat;
      background-size: contain;
      transform: rotate(180deg);
      transition: 0.3s linear;
    }
  }
}
.title {
  color: var(--el-color-primary);
  font-family: "Merriweather", serif;
  font-size: 1.7rem;
}
.main-header {
  position: sticky;
  z-index: 2001;
  top: 0;
  background: var(--el-bg-color-overlay);
  border-bottom: 1px solid var(--el-menu-border-color);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}
</style>
